<template>
  <MDBCard class="m-3 form" :class="{ 'd-none':!Model }">
    <MDBCardBody>
      <form @submit="Submit">
        <!-- <MDBRow class="mb-3 mt-3 text-start" v-if="Action == 'insert'">
          <MDBCol >
            <h5> {{ $t("COMMON.SELECTLANGUAGE") }}</h5>
          </MDBCol>
        </MDBRow> -->
        <!-- <MDBRow class="mb-3" v-if="action == 'insert'">
          <div class="mb-3">
            <div>
              <label for="exampleFormControlInput1" class="form-label text-dark">{{ $t('COMMON.LANGUAGE') }}<span class="text-danger">*</span></label>  
            </div>
            <template v-for="(lang ,k) in languages" :key="k">
              <div class="form-check">
                <input class="form-check-input" type="radio" v-bind:id="`${Action}-lang-${lang.lang}`" :value="lang.lang" v-model="Form.lang.value">
                <label class="form-check-label" v-bind:for="`${Action}-lang-${lang.lang}`">
                  {{ $t(`LANG.${lang.lang}`) }}
                </label>
              </div>
            </template>
            <span class="text-danger">{{ langError }}</span>
          </div>
        </MDBRow> -->
        <MDBRow class="mb-3 mt-3" >
          <MDBCol md="12">
            <label for="exampleFormControlInput1" class="form-label text-dark">問題狀態<span class="text-danger">*</span></label>
            <select class="form-control" v-model="Form.status.value">
              <option value="0">未解決</option>
              <option value="1">解決中</option>
              <option value="2">已解決</option>
            </select>
          </MDBCol>
          <MDBCol md="12">
            <MDBRow class="g-3">
              <MDBCol md="12">
                <div>
                  <Ckeditor5 :label="$t('COMMON.CONTENT')" v-model="Form.content.value"></Ckeditor5>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
        <button type="submit" class="btn btn-danger" v-if="action == 'insert'" :disabled="SubmitLoading">
          回覆此則討論
          <span class="spinner-border Loading" :class="{ 'd-none':!SubmitLoading }" role="status" >
            <span class="visually-hidden">Loading...</span>
          </span>
        </button>
        <MDBBtn color="primary" type="submit" v-if="action == 'update'" :disabled="SubmitLoading">
          {{ $t("COMMON.SAVE") }}
          <span class="spinner-border Loading" :class="{ 'd-none':!SubmitLoading }" role="status" >
            <span class="visually-hidden">Loading...</span>
          </span>
        </MDBBtn>
        <MDBBtn v-if="action == 'update'" type="button" @click="Close">{{ $t("COMMON.CANCEL") }}</MDBBtn>
      </form>
    </MDBCardBody>
  </MDBCard>
</template>

<style scope>
  .category {
    list-style: none;
  }
  .bg-success {
    color: #ffffff !important;
    background-color: #1BC5BD !important;
    border-color: #1BC5BD !important;
  }
  .bg-warning {
    color: #ffffff !important;
    background-color: #7337EE !important;
    border-color: #7337EE !important;
  }
  .bg-danger {
    color: #3F4254 !important;
    background-color: #d7dae7 !important;
    border-color: #d7dae7 !important;
  }
</style>

<script>
import { MDBRow, MDBBtn, MDBCol, MDBCard, MDBCardBody } from 'mdb-vue-ui-kit';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import { computed, inject, ref } from "vue";
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { useI18n } from 'vue-i18n';
import i18nService from "@/core/services/i18n.service.js";
import Ckeditor5 from '@/components/Plugin/Ckeditor5';

export default {
  name: "CommentForm",
  components: {
    MDBRow,
    MDBBtn,
    MDBCol,
    MDBCard,
    MDBCardBody,
    Ckeditor5,
  },
  props: {
    action: {
      type: String,
      default: ""
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    uuid: {
      type: String,
      default: ""
    },
    article: {
      type: String,
      default: ""
    }
  },
  setup(props, { emit }) {
    const Model = computed({
      get: () => props.modelValue,
      set: val => emit("update:modelValue", val)
    });
    const Action = computed({
      get: () => props.action,
      set: val => emit("update:action", val)
    });
    const FormUUid = computed({
      get: () => props.uuid,
      set: val => emit("update:uuid", val),
    });

    const i18n = useI18n();
    const languages = i18nService.languages;
    const SubmitLoading = ref(false);
    const ArticleDetail = inject("ArticleDetail");

    const basicSchema = {
      lang: yup.string().label(i18n.t("COMMON.LANGUAGE")),
      status: yup.string().required().label(i18n.t("COMMON.STATUS")),
      content: yup.string().required().label(i18n.t("COMMON.PRODUCTDESCRIPTION")),
    };

    let ExtendSchema = {};
    const FormSchema = yup.object(Object.assign(basicSchema,ExtendSchema));

    const { handleSubmit, resetForm } = useForm({
      validationSchema: FormSchema
    });

    const { value: lang, errorMessage: langError } = useField('lang');
    const { value: status, errorMessage: statusError } = useField('status');
    const { value: content, errorMessage: contentError } = useField('content');

    const Init = function(){
      lang.value = i18nService.getActiveLanguage();
      status.value = "1";
    }

    let Submit;

    if(Action.value == "insert") {
      Submit = handleSubmit(values => {
        SubmitLoading.value = true;
        ApiService.post("/Admin/v1/Comment", Object.assign(values,{article:props.article})).then(response => {
          SubmitLoading.value = false;
          if (response.status == 200) {
            CommonService.AxiosHandle(response);
            resetForm();
            Init();
            ArticleDetail();
          }
        });
      });
    }else{
      Submit = handleSubmit(values => {
        SubmitLoading.value = true;
        ApiService.update("/Admin/v1/Comment",props.uuid ,values).then(response => {
          SubmitLoading.value = false;
          if (response.status == 200) {
            CommonService.AxiosHandle(response);
            resetForm();
            Model.value = false;
            Init();
            FormUUid.value = "";
            ArticleDetail();
          }
        });
      });
    }

    const GetDetail = function() {
      resetForm();
      Form.lang.value = i18nService.getActiveLanguage();
      Promise.all([
        new Promise(resolve => {
          ApiService.get("/Admin/v1/Comment", FormUUid.value).then(response => {
            if (response.status == 200) {
              for (let k in response.data.data) {
                if(typeof Form[k] != "undefined" && response.data.data[k] != null) {
                  switch (k) {
                    default:
                      Form[k].value = response.data.data[k].toString();
                    break;
                  }
                }
              }
              Model.value = true;
              resolve(response);
            }
          });
        })
      ]);
    }

    Init();

    const Form = {
      lang,
      status,
      content,
    };

    const Close = function() {
      FormUUid.value = "";
      Model.value = false;
    };

    return {
      Action,
      Model,
      SubmitLoading,
      Form,
      langError,
      statusError,
      contentError,
      Submit,
      resetForm,
      FormUUid,
      languages,
      Close,
      GetDetail
    };
  },
  watch: {
    uuid: function(val){
      if(val){
        this.GetDetail();  
      }
    }
  },
  // methods: {
  //   GetDetail() {
  //     this.resetForm();
  //     this.Form.lang = i18nService.getActiveLanguage();
  //     Promise.all([
  //       new Promise(resolve => {
  //         ApiService.get("/Admin/v1/Comment", this.FormUUid).then(response => {
  //           if (response.status == 200) {
  //             for (let k in response.data.data) {
  //               if(typeof this.Form[k] != "undefined" && response.data.data[k] != null) {
  //                 switch (k) {
  //                   default:
  //                     this.Form[k].value = response.data.data[k].toString();
  //                   break;
  //                 }
  //               }
  //             }
  //             this.Model = true;
  //             resolve(response);
  //           }
  //         });
  //       })
  //     ]);
  //   }
  // }
};
</script>