<template>
  <MDBCard class="m-3 form" :class="{ 'd-none':!Model }">
    <MDBCardBody>
      <form @submit="Submit">
        <MDBCardHeader v-if="(Action == 'insert')">{{ $t("FORUM.ADD-ITEM") }}</MDBCardHeader>
        <MDBCardHeader v-else-if="(Action == 'update')">{{ $t("FORUM.EDIT-ITEM") }}</MDBCardHeader>
        <MDBRow class="mb-3 mt-3 text-start" v-if="Action == 'insert'">
          <MDBCol >
            <h5> {{ $t("COMMON.SELECTLANGUAGE") }}</h5>
          </MDBCol>
        </MDBRow>
        <MDBRow class="mb-3" v-if="action == 'insert'">
          <div class="mb-3">
            <div>
              <label for="exampleFormControlInput1" class="form-label text-dark">{{ $t('COMMON.LANGUAGE') }}<span class="text-danger">*</span></label>  
            </div>
            <template v-for="(lang ,k) in languages" :key="k">
              <div class="form-check">
                <input class="form-check-input" type="radio" v-bind:id="`${Action}-lang-${lang.lang}`" :value="lang.lang" v-model="Form.lang.value">
                <label class="form-check-label" v-bind:for="`${Action}-lang-${lang.lang}`">
                  {{ $t(`LANG.${lang.lang}`) }}
                </label>
              </div>
            </template>
            <span class="text-danger">{{ langError }}</span>
          </div>
        </MDBRow>
        <!-- <MDBRow class="mb-3 mt-3 text-start">
          <MDBCol>
            <h5 >{{ $t("COMMON.SETCONTENT") }}</h5>
          </MDBCol>
        </MDBRow> -->
        <MDBRow class="mb-3 mt-3" >
          <MDBCol md="12">
            <MDBRow class="g-3">
              <MDBCol md="12">
                <div>
                  <label class="form-label text-dark">{{ $t('COMMON.PRODUCT') }}<span class="text-danger">*</span></label>
                  <select class="form-control" v-model="Form.product.value">
                    <option value="0">--請選擇產品--</option>
                    <template v-for="(p,k) in Product.filter(item=>item.lang==Form.lang.value)" :key="k">
                      <option :value="p.uuid">{{p.category[0].name[Form.lang.value].name}} , {{p.name}}</option>
                    </template>
                  </select>
                  <span class="text-danger">{{ productError }}</span>
                </div>
              </MDBCol>
              <MDBCol md="12">
                <div>
                  <label class="form-label text-dark">{{ $t('COMMON.TITLE') }}<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" name="title" :placeholder="$t('COMMON.TITLE')" v-model="Form.title.value">
                  <span class="text-danger">{{ titleError }}</span>
                </div>
              </MDBCol>
              <MDBCol md="12">
                <div>
                  <label class="form-label text-dark">{{ $t('COMMON.CONTENT') }}<span class="text-danger">*</span></label>
                  <Ckeditor5 label="" v-model="Form.content.value"></Ckeditor5>
                  <span class="text-danger">{{ contentError }}</span>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
        <MDBBtn color="primary" type="submit"  v-if="action == 'insert'" :disabled="SubmitLoading">
          {{ $t("COMMON.INSERT") }}
          <span class="spinner-border Loading" :class="{ 'd-none':!SubmitLoading }" role="status" >
            <span class="visually-hidden">Loading...</span>
          </span>
        </MDBBtn>
        <MDBBtn color="primary" type="submit" v-if="action == 'update'" :disabled="SubmitLoading">
          {{ $t("COMMON.SAVE") }}
          <span class="spinner-border Loading" :class="{ 'd-none':!SubmitLoading }" role="status" >
            <span class="visually-hidden">Loading...</span>
          </span>
        </MDBBtn>
        <MDBBtn type="button" @click="Close">{{ $t("COMMON.CLOSE") }}</MDBBtn>
      </form>
    </MDBCardBody>
  </MDBCard>
</template>

<style scope>
  .category {
    list-style: none;
  }
</style>

<script>
import { MDBRow, MDBBtn, MDBCol, MDBCard, MDBCardBody, MDBCardHeader } from 'mdb-vue-ui-kit';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import { computed, inject, ref, reactive } from "vue";
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { useI18n } from 'vue-i18n';
import i18nService from "@/core/services/i18n.service.js";
import Ckeditor5 from '@/components/Plugin/Ckeditor5';

export default {
  name: "ArticleForm",
  components: {
    MDBRow,
    MDBBtn,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    Ckeditor5,
  },
  props: {
    action: {
      type: String,
      default: ""
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    uuid: {
      type: String,
      default: ""
    },
  },
  inject: ['Product'],
  setup(props, { emit }) {
    const Model = computed({
      get: () => props.modelValue,
      set: val => emit("update:modelValue", val)
    });
    const Action = computed({
      get: () => props.action,
      set: val => emit("update:action", val)
    });
    const FormUUid = computed({
      get: () => props.uuid,
      set: val => emit("update:uuid", val),
    });

    const GetTable = inject("GetTable");
    const i18n = useI18n();
    const languages = i18nService.languages;
    const SubmitLoading = ref(false);
    const Products = reactive([]);

    const basicSchema = {
      lang: yup.string().required().label(i18n.t("COMMON.LANGUAGE")),
      product: yup.string().required().label(i18n.t("COMMON.PRODUCT")),
      title: yup.string().required().label(i18n.t("COMMON.TITLE")),
      content: yup.string().required().label(i18n.t("COMMON.CONTENT")),
    };

    let ExtendSchema = {};
    const FormSchema = yup.object(Object.assign(basicSchema,ExtendSchema));

    const { handleSubmit, resetForm } = useForm({
      validationSchema: FormSchema
    });

    const { value: lang, errorMessage: langError } = useField('lang');
    const { value: product, errorMessage: productError } = useField('product');
    const { value: title, errorMessage: titleError } = useField('title');
    const { value: content, errorMessage: contentError } = useField('content');

    const Init = function(){
      lang.value = i18nService.getActiveLanguage();
      product.value = "0";
    }

    let Submit;

    if(Action.value == "insert") {
      Submit = handleSubmit(values => {
        SubmitLoading.value = true;
        ApiService.post("/Admin/v1/Article", values).then(response => {
          SubmitLoading.value = false;
          if (response.status == 200) {
            CommonService.AxiosHandle(response);
            resetForm();
            Model.value = false;
            Init();
            GetTable();
          }
        });
      });
    }else{
      Submit = handleSubmit(values => {
        SubmitLoading.value = true;
        ApiService.update("/Admin/v1/Article",props.uuid ,values).then(response => {
          SubmitLoading.value = false;
          if (response.status == 200) {
            CommonService.AxiosHandle(response);
            resetForm();
            Model.value = false;
            Init();
            FormUUid.value = "";
            GetTable();
          }
        });
      });
    }

    Init();

    const Form = {
      lang,
      title,
      product,
      content,
    };

    const GetProduct = function() {
      return new Promise(resolve => {
        ApiService.query(
          "/Admin/v1/Product",
          {
            params: Object.assign(CommonService.SortHandleObject({
              page: 1,
              itemsPerPage: -1,
              sortBy: ["created_at"],
              sortDesc: []
            }),{status:1})
          }
        ).then(response => {
          if (response.status == 200) {
            response.data.data.data.map(product => {
              Products.push(product);
            });
            resolve(response);
          }
        });
      });
    };

    const Close = function() {
      FormUUid.value = "";
      Model.value = false;
    };

    return {
      Action,
      Model,
      SubmitLoading,
      Form,
      langError,
      titleError,
      productError,
      contentError,
      Submit,
      resetForm,
      FormUUid,
      languages,
      GetProduct,
      Close
    };
  },
  watch: {
    uuid: function(val){
      if(val){
        this.GetDetail();  
      }
    }
  },
  methods: {
    GetDetail() {
      this.resetForm();
      this.Form.lang = i18nService.getActiveLanguage();
      Promise.all([
        new Promise(resolve => {
          ApiService.get("/Admin/v1/Article", this.FormUUid).then(response => {
            if (response.status == 200) {
              for (let k in response.data.data) {
                if(typeof this.Form[k] != "undefined" && response.data.data[k] != null) {
                  switch (k) {
                    case "productsable_id":
                      this.Form['product'].value = response.data.data[k].toString();
                    break;
                    default:
                      this.Form[k].value = response.data.data[k].toString();
                    break;
                  }
                }
              }
              this.Model = true;
              resolve(response);
            }
          });
        })
      ]);
    }
  }
};
</script>