<template>
  <MDBCard class="m-3" :class="{ 'd-none':!Model }">
    <MDBCardBody>
	<MDBContainer fluid class="mt-3">
    <MDBRow>
      <MDBCol>
        <div class="messageFrame" v-if="typeof(data.title) != 'undefined'">
          <div class="messageList d-flex flex-row align-items-top justify-content-start border-bottom mb-3 pb-2">
            <div class="mb-2 messageContent d-flex flex-column align-items-start justify-content-start">
              <h4 class="m-2 proUnit text-warning">{{ data.product.category[0].name[languages].name }}，{{data.product.name}}</h4>
              <h5 class="mx-2 my-1 proTitle">{{ data.title }}</h5>
              <div class="m-2 messageToggleRow d-flex flex-row">
                <div class="userName">
                  <span class="badge px-5 badge-info badge-pill" v-if="!data.member">{{ data.admin.name }}</span>
                  <span class="badge px-5 badge-info badge-pill" v-else>{{ data.member.name }}</span>
                </div>
                <div class="px-3 postDate">
                  <p class="text-center">{{ Common.TimeFormat(data.created_at) }}</p>
                </div>
              </div>
              <div id="collapse-message1" class="border-top py-3 collapse show" style="">
                <p class="m-2 mb-8 proTitle" v-html="data.content"></p>
                <template v-for="(c,k) in data.comment" :key="k">
                  <div class="message d-flex flex-row align-items-top justify-content-start m-2 mb-3 pb-2 bg-light rounded">
                    <div class="m-2 w-auto messageContent d-flex flex-column align-items-start justify-content-start">
                      <div class="messageReply">
                        <div class="mt-2 messageToggleRow d-flex flex-row">
                          <div class="userName">
                            <span class="badge px-5 badge-warning badge-pill" v-if="!c.member">{{ c.admin.name }}</span>
                            <span class="badge px-5 badge-warning badge-pill" v-else>{{ c.member.name }}</span>
                          </div>
                          <div class="px-3 postDate">
                            <p class="text-center">{{ Common.TimeFormat(c.created_at) }}</p>
                          </div>
                          <!-- <MDBBtn type="button" color="info" v-if="!c.member && c.admin.uuid == currentUserAccountInfo.user.uuid">{{ $t("COMMON.UPDATE") }}</MDBBtn> -->
                        </div>
                        <div class="messageReplyContent" v-html="c.content">
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="mb-2 messageStatus d-flex flex-column align-items-center justify-content-start">
              <button type="button" class="btn m-2 py-2" :class="{ 'bg-danger': (data.status == 0),'bg-warning': (data.status == 1),'bg-success': (data.status == 2)}">{{ $t("FORUM.STATUS_" + data.status) }}</button>
            </div>
          </div>
        </div>
        <CommentForm v-model="add_dialog" action="insert" :article="uuid"></CommentForm>
        <MDBBtn type="button" @click="Close">{{ $t("COMMON.CLOSE") }}</MDBBtn>
      </MDBCol>
    </MDBRow>
	</MDBContainer>
  </MDBCardBody>
  </MDBCard>
</template>

<style scope>
  .badge-info {
    color: #ffffff;
    background-color: #8950FC;
  }
  .badge-warning {
    color: #181C32;
    background-color: #FFA800;
  }
  .messageReplyContent * {
    white-space: normal;
    word-break: break-word;
  }
</style>

<script>
import { MDBContainer,MDBRow, MDBCol, MDBBtn, MDBCard, MDBCardBody } from 'mdb-vue-ui-kit';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import Swal from "sweetalert2";
import { computed, inject, ref } from "vue";
import { useI18n } from 'vue-i18n';
import i18nService from "@/core/services/i18n.service.js";
import CommentForm from '@/components/Form/Forum/CommentForm';
import { mapGetters } from 'vuex';

export default {
  name: "ArticleDetail",
  components: {
		MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBBtn,
    MDBCardBody,
    CommentForm
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    uuid: {
      type: String,
      default: ""
    },
  },
  computed: {
    ...mapGetters([
      "currentUserAccountInfo"
    ])
  },
  setup(props, { emit }) {
    const Model = computed({
      get: () => props.modelValue,
      set: val => emit("update:modelValue", val)
    });
    const FormUUid = computed({
      get: () => props.uuid,
      set: val => emit("update:uuid", val),
    });
    const add_dialog = ref(true);
    const GetTable = inject("GetTable");
    const i18n = useI18n();
    const SubmitLoading = ref(false);
    const Common = CommonService;
    const EditUUid = ref("");
    const data = ref({});
    const languages = i18nService.getActiveLanguage();

    const DeleteAction = function(uuid) {
      Swal.fire({
        title: i18n.t("COMMON.WARNING"),
        html: i18n.t("COMMON.CONFIRM-DELETE"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: i18n.t("COMMON.CONFIRM"),
        cancelButtonText: i18n.t("COMMON.CANCEL")
      }).then(result => {
        if (result.isConfirmed) {
          ApiService.delete("/Admin/v1/Article/" + uuid).then(
            response => {
              if (response.status == 200) {EditUUid
                CommonService.AxiosHandle(response);
                GetTable();
              }
            }
          );
        }
      });
    };

    const Close = function() {
      FormUUid.value = "";
      Model.value = false;
    };

		return {
      add_dialog,
      Model,
      SubmitLoading,
      FormUUid,
      Common,
      Close,
      EditUUid,
      DeleteAction,
      data,
      languages
    };
  },
  watch: {
    uuid: function(val){
      if(val){
        this.GetDetail();
      }
    }
  },
  methods: {
    GetDetail() {
      Promise.all([
        new Promise(resolve => {
          ApiService.get("/Admin/v1/Article", this.FormUUid).then(response => {
            if (response.status == 200) {
              this.data = response.data.data;
              this.Model = true;
              resolve(response);
            }
          });
        })
      ]);
    }
  },
  provide() {
    return {
      ArticleDetail: this.GetDetail  
    }
  }
};
</script>