<template>
	<Breadcrumbs></Breadcrumbs>
  <MDBCard class="m-3" :class="{ 'd-none':(add_dialog || edit_dialog || detail)}">
    <MDBCardBody>
	<MDBContainer fluid class="mt-3">
    <transition name="fade">
      <PageLoading v-if="PageLoading"></PageLoading>
    </transition>
    <MDBRow>
      <MDBCol class="mb-2 text-start" md="2">
          <MDBSelect v-model:options="language.items" :label="$t('COMMON.LANGUAGE')" v-model:selected="language.selected"></MDBSelect>
        </MDBCol>
      <MDBCol md="2">
        <select class="form-select" v-model="where.status">
          <option value="">狀態</option>
          <option value="0">{{$t("FORUM.STATUS_0")}}</option>
          <option value="1">{{$t("FORUM.STATUS_1")}}</option>
          <option value="2">{{$t("FORUM.STATUS_2")}}</option>
        </select>
      </MDBCol>
      <MDBCol md="2">
        <MDBInput :label="$t('COMMON.TITLE')" :placeholder="$t('COMMON.TITLE')" v-model="where.title" />
      </MDBCol>
      <MDBCol class="mb-2 text-end">
        <MDBBtn color="primary" @click="GetTable">{{ $t("COMMON.SEARCH") }}</MDBBtn>
        <MDBBtn color="info" @click="add_dialog = true">{{ $t("FORUM.ADD-ITEM") }}</MDBBtn>  
      </MDBCol>
    </MDBRow>
		<DataTable
			:header="headers"
			:tabledata="desserts"
			v-model:total="total"
			v-model:options="options"
			v-model:loading="loading"
		>
      <template v-slot:category="{ item }">
        {{ (item.product.category.length > 0)?item.product.category[0].name[item.lang].name:"" }}
      </template>
      <template v-slot:lang="{ item }">
        {{ $t("LANG." + item.lang) }}
      </template>
			<!-- 時間轉換 -->
      <template v-slot:created_at="{ item }">
        {{ Common.TimeFormat(item.created_at) }}
      </template>
      <template v-slot:status="{ item }">
        <div class="badge text-wrap" :class="{ 'bg-danger': (item.status == 0),'bg-warning': (item.status == 1),'bg-success': (item.status == 2)}">
          {{ $t("FORUM.STATUS_" + item.status) }}
        </div>
        
      </template>
      <template v-slot:member="{ item }">
        <span v-if="!item.member">{{ item.admin.name }}</span>
        <span v-else>{{ item.member.name }}</span>
      </template>
      <!-- 操作template -->
      <!-- <template v-slot:uuid="{ item }">
				<a @click="EditUUid = item.uuid" data-bs-toggle="tooltip" :title="$t('FORUM.EDIT-ITEM')" href="javascript:void(0)" class="me-2">
					<MDBIcon icon="edit" iconStyle="fas" class="text-primary"/>
				</a>
				<a @click="DeleteAction(item.uuid)" data-bs-toggle="tooltip" :title="$t('FORUM.DELETE-ITEM')" href="javascript:void(0)" class="me-2">
					<MDBIcon icon="trash-alt" iconStyle="fas" class="text-danger"/>
				</a>
      </template> -->
      <template v-slot:edit="{ item }">
        <MDBBtn color="info" @click="EditUUid = item.uuid" class="px-3 py-2">
          <MDBIcon icon="edit" iconStyle="fas" class="text-white"/>
        </MDBBtn>
      </template>
      <template v-slot:uuid="{ item }">
        <MDBBtn color="danger" @click="DeleteAction(item.uuid)" class="px-3 py-2">
          <MDBIcon icon="trash-alt" iconStyle="fas" class="text-white"/>
        </MDBBtn>
      </template>
		</DataTable>
	</MDBContainer>
  </MDBCardBody>
  </MDBCard>
	<ArticleForm v-model="add_dialog" action="insert"></ArticleForm>
  <ArticleDetail v-model="detail" v-model:uuid="EditUUid"></ArticleDetail>
  <!-- <ArticleForm v-model="edit_dialog" action="update" v-model:uuid="EditUUid"></ArticleForm> -->
</template>

<style scoped>
  .sub-category {
    list-style: auto;
    text-align: start;
  }
  .sub-category li {
    border-bottom: 1px solid;
    width: fit-content;
  }
  .sub-div {
    display: inline-block;
    margin-right: 10px;
  }
  .bg-success {
    color: #ffffff !important;
    background-color: #1BC5BD !important;
    border-color: #1BC5BD !important;
  }
  .bg-warning {
    color: #ffffff !important;
    background-color: #7337EE !important;
    border-color: #7337EE !important;
  }
  .bg-danger {
    color: #3F4254 !important;
    background-color: #d7dae7 !important;
    border-color: #d7dae7 !important;
  }
</style>

<script>
import { MDBContainer, MDBIcon, MDBBtn, MDBRow, MDBCol, 
  MDBSelect, 
  MDBCard, MDBCardBody, MDBInput } from 'mdb-vue-ui-kit';
import DataTable from '@/components/Plugin/DataTable';
import Breadcrumbs from '@/components/Breadcrumbs';
import ArticleForm from '@/components/Form/Forum/ArticleForm';
import ArticleDetail from '@/components/ArticleDetail';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import i18nService from "@/core/services/i18n.service.js";
import PageLoading from '@/components/Elements/PageLoading';
import { ref, reactive } from "vue";
import { useI18n } from 'vue-i18n';

export default {
  name: "Forum",
  components: {
		DataTable,
		MDBContainer,
		MDBIcon,
		Breadcrumbs,
		ArticleForm,
    ArticleDetail,
		MDBBtn,
    MDBRow,
    MDBCol,
    MDBSelect,
    MDBCard,
    MDBCardBody,
    PageLoading,
    MDBInput
  },
  setup() {
    const i18n = useI18n();
    const PageLoading = ref(true);
    const add_dialog = ref(false);
    const edit_dialog = ref(false);
    const detail = ref(false);
    const loading = ref(true);
    const total = ref(0);
    const Common = CommonService;
    const ProductCategory = reactive([]);
    const EditUUid = ref("");
    const desserts = ref([]);
    const Product = reactive([]);
    const language = reactive({
      items: i18nService.languages.map(item => { item.selected = (item.lang == i18nService.getActiveLanguage()); return item;}),
      selected: i18nService.getActiveLanguage(),
    });

    const options = reactive({
      page: 1,
      itemsPerPage: 10,
      sortBy: ["created_at"],
      sortDesc: [true],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false
    });

    const where = reactive({
      status: "",
      title: "",
    });

    const headers = reactive([
      {
        text: i18n.t("COMMON.SEQUENCE"),
        align: "start",
        sortable: false,
        value: "no",class: "text-center",width:"50px"
      },
      { text: i18n.t("COMMON.CATEGORY"), value: "category" },
      { text: i18n.t("COMMON.NAME"), value: "product.name" },
      { text: i18n.t("COMMON.TITLE"), value: "title" },
      { text: i18n.t("COMMON.MEMBERNAME"), value: "member" },
      { text: i18n.t("COMMON.CREATE_AT"), value: "created_at" },
      { text: i18n.t("COMMON.STATUS"), value: "status" },
      { text: i18n.t("COMMON.EDIT"), value: "edit", sortable: false ,class: "text-center",width:"50px"},
      { text: i18n.t("COMMON.DELETE"), value: "uuid", sortable: false ,class: "text-center",width:"50px"}
    ]);

    const SelectedLanguage = function() {
      language.items.map(item => {
        if(item.lang == i18nService.getActiveLanguage()){
          item.selected = true;
        }else{
          item.selected = false;
        }
      });
    };

    const GetTable = function() {
      return new Promise(resolve => {
        loading.value = true;
        ApiService.query(
          "/Admin/v1/Article",
          {
            params: Object.assign(CommonService.SortHandleObject(options),where,{lang:language.selected})
          }
        ).then(response => {
          if (response.status == 200) {
            desserts.value = CommonService.DataNoHandle(
              response.data.data.data,
              options.page,
              options.itemsPerPage
            );
            total.value = response.data.data.total;
            resolve(response);
          }
        });
      });
    };

    const GetProduct = function() {
      return new Promise(resolve => {
        ApiService.query(
          "/Admin/v1/Product",
          {
            params: Object.assign(CommonService.SortHandleObject({
              page: 1,
              itemsPerPage: -1,
              sortBy: ["created_at"],
              sortDesc: []
            }),{status:1})
          }
        ).then(response => {
          if (response.status == 200) {
            response.data.data.data.map(product => {
              if(product.category.length > 0) {
                Product.push(product);
              }
            });
            resolve(response);
          }
        });
      });
    };

    const DeleteAction = function(uuid) {
      CommonService.DeleteSwal().then(result => {
        if (result.isConfirmed) {
          ApiService.delete("/Admin/v1/Article/" + uuid).then(
            response => {
              if (response.status == 200) {
                CommonService.AxiosHandle(response);
                GetTable();
              }
            }
          );
        }
      });
    };

    

		return {
      PageLoading,
      add_dialog,
      edit_dialog,
      detail,
      loading,
      total,
      Common,
      ProductCategory,
      options,
      headers,
      desserts,
      EditUUid,
      Product,
      language,
      SelectedLanguage,
      GetTable,
      GetProduct,
      DeleteAction,
      where
    };
  },
  created() {
    this.GetTable();
  },
  mounted() {
    Promise.all([
      this.GetProduct()
    ]).then(() => {
      this.PageLoading = false;
    }).catch(error => {
      console.log(error);
    });
  },
  watch: {
		options: {
      handler() {
        this.GetTable();
      },
      deep: true
    },
    // "language.selected": function(){
    //   this.GetTable();
    // }
  },
  provide () {
    return {
      GetTable: this.GetTable,
      Product: this.Product,
    }
  }
};
</script>